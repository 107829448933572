<lib-logo-frame>
    <ng-container form-title>
        Accedi al tuo account
        
        @if(auth_cfg.user_can_register) {
            oppure <a mat-button color="primary" [routerLink]="['/', ROUTER_TOKENS.REGISTER]">Registrati</a>
        }
    
    </ng-container>
    
    <form [formGroup]="formData" autocomplete="off" novalidate class="flex flex-col">
        <mat-form-field>
            <mat-label>Inserisci la tua email</mat-label>
            <input matInput type="text" placeholder="nome@esempio.com" formControlName="email" required autocomplete="username">
            <mat-icon matSuffix>email</mat-icon>
            @if(formData.controls.email.errors?.['required']) {
                <mat-error>Inserire email</mat-error>
            }
            @if(formData.controls.email.errors?.['email']) {
                <mat-error>&apos;{{formData.controls.email.value}}&apos; non &egrave; una email valida</mat-error>
            }
            @if(formData.controls.email.errors?.['maxLength']) {
                <mat-error>Email troppo lunga</mat-error>
            }  
        </mat-form-field>            
        <mat-form-field class="mt-2">
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName="password" required autocomplete="current-password">
            <mat-icon matSuffix>vpn_key</mat-icon>
            @if(formData.controls.password.errors?.['required']) {
                <mat-error>Inserire password</mat-error>
            }
        </mat-form-field>
    </form>

    <lib-loading-big-button
        class="block mt-5"
        [loading]="loading()" 
        [disabled]="formData.invalid || loading()" 
        (onClick)="login()">
        LOGIN
    </lib-loading-big-button>
    
        
    @if (error()) {
        <lib-error-alert>{{ error() }}</lib-error-alert>
    }

    <!--
    <a form-footer mat-button color="primary" [routerLink]="['/', ROUTER_TOKENS.FORGOT_PWD]">Password dimenticata ?</a>
    -->
    
</lib-logo-frame>


