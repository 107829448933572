import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	OnInit,
	inject,
	signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LogoFrameComponent } from '../logo-frame/logo-frame.component';
import { Observable, concatMap, of } from 'rxjs';
import {
	ErrorAlertComponent,
	LoadingBigButtonComponent,
	LoadingButtonComponent,
} from '@webapp-nx-repo/lib-shared-common';
import { AUTH_ROUTER_TOKENS } from '../routes/router-tokens';
import { AUTH_CONFIG } from '../../provider/auth-config';
import { LibAuthService } from '../../service/lib-auth.service';
import { IUser } from '../../model/user';

@Component({
	selector: 'lib-auth-login',
	imports: [
		CommonModule,
		RouterLink,
		ErrorAlertComponent,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatProgressSpinnerModule,
		LogoFrameComponent,
        LoadingBigButtonComponent,
	],
	templateUrl: './login.component.html',
	styles: [``],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
	private readonly destroyRef = inject(DestroyRef);
	readonly ROUTER_TOKENS = AUTH_ROUTER_TOKENS;
	readonly auth_cfg = inject(AUTH_CONFIG);
	readonly router = inject(Router);
	readonly activateRoute = inject(ActivatedRoute);
	readonly authService = inject(LibAuthService);
	readonly fb = inject(FormBuilder);

	loading = signal<boolean>(false);
	error = signal<string | null>(null);

	formData: FormGroup<{
		email: FormControl<string | null>;
		password: FormControl<string | null>;
	}> = this.fb.group({
		email: this.fb.control<string | null>(null, [
			Validators.required,
			Validators.email,
			Validators.minLength(6),
			Validators.maxLength(191),
		]),
		password: this.fb.control<string | null>(null, [
			Validators.required,
			Validators.minLength(8),
			Validators.maxLength(191),
		]),
	});

	ngOnInit(): void {
		if (this.authService.isLoggedIn()) {
			this.router.navigateByUrl('/');
		}
	}

	login() {
		this.loading.set(true);
		this.error.set(null);

		this.authService
			.login(
				this.formData.controls.email.value!,
				this.formData.controls.password.value!
			)
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				concatMap((user: IUser|null): Observable<IUser | null> => 
                    {
						if (user === null || user.two_factor) {
							return of(null); // finchè non sono pienamente autenticato, il middleware laravel mi torna err su getLoggedUser ..
						} else {
							return this.authService.getLoggedUser(); // save LoggedUser in authService
						}
					}
				)
			)
			.subscribe({
				next: (user_data: IUser | null) => {
					this.loading.set(false);
					this.error.set(null);

					if (user_data) {
						const url = this.authService.redirectUrl || '/';
						this.router.navigateByUrl(url);
					} else {
						//this.router.navigateByUrl(this.ROUTER_TOKENS.LOGIN2FA);
					}
				},
				error: (err: string) => {
					this.loading.set(false);
					this.error.set('Utente o password non validi.');
				},
			});
	}
}
